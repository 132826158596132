import React from "react";
import { Link } from "gatsby";

const Footer = ({ className, ...rest }) => {
  return (
    <>
      <div className={`pt-13 pt-lg-25 pb-13 pb-lg-18 ${className}`} {...rest}>
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                {/* Single Widgets */}
                <div className="col-md-4 col-xs-6">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-6 font-weight-medium mb-10">
                      Links
                    </h4>
                    <ul className="list-unstyled">
                      <li className="mb-6">
                        <Link
                          to="/faq"
                          className="font-size-5 text-default-color"
                        >
                          Preguntas Frecuentes
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/acerca"
                          className="font-size-5 text-default-color"
                        >
                          Acerca de nosotros
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/contacto"
                          className="font-size-5 text-default-color"
                        >
                          Contacto
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/#agenda"
                          className="font-size-5 text-default-color"
                        >
                          Agenda
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
                {/* Single Widgets */}
                {/* <div className="col-md-4 col-xs-6">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-6 font-weight-medium mb-10">
                      Acerca de Nosotros
                    </h4>
                    <ul className="list-unstyled">
                      <li className="mb-6">
                        <Link
                          to="/acerca/#equipo"
                          className="font-size-5 text-default-color"
                        >
                          Nuestro Equipo
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/#"
                          className="font-size-5 text-default-color"
                        >
                          Our Story
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/#"
                          className="font-size-5 text-default-color"
                        >
                          Career
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/* End Single Widgets */}
              </div>
            </div>
            {/* Single Widgets */}
            <div className="col-xl-4 col-lg-5 col-md-4 col-xs-8">
              <div className="mb-10 mb-lg-0">
                <h4 className="font-size-5 opacity-4 mb-10">
                  Visita Nuestras Redes Sociales. También nos puedes contactar
                  por chat
                </h4>
                <ul className="footer-social-link d-flex align-items-center justify-content-between list-unstyled flex-wrap mb-10">
                  <li>
                    <Link
                      to="https://instagram.com/mi_diagnostico"
                      className="text-default-color-2 gr-hover-text-blue-3"
                    >
                      Instagram
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/#"
                      className="text-default-color-2 gr-hover-text-blue-3"
                    >
                      .
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/#"
                      className="text-default-color-2 gr-hover-text-blue-3"
                    >
                      Twitter
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/#"
                      className="text-default-color-2 gr-hover-text-blue-3"
                    >
                      .
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/#"
                      className="text-default-color-2 gr-hover-text-blue-3"
                    >
                      Instagram
                    </Link>
                  </li> */}
                </ul>
              </div>
              <div className="border-top border-default-color-4 pt-10">
                <div className="mb-3">
                  {/* <img className="w-100" src={imgP1} alt="" /> */}
                </div>
                <div className="mb-3">
                  {/* <img className="w-100" src={imgP2} alt="" /> */}
                </div>
              </div>
            </div>
            {/* End Single Widgets */}
            {/* <div className="col-lg-5 col-md-8">
              <div className="mb-7">
                <h4 className="font-size-6 font-weight-medium mb-8">
                  Visita Nuestras Redes Sociales
                </h4>
                <p className="font-size-5 line-height-28">
                  También nos puedes contactar directamente por el chat de
                  nuestras redes sociales
                </p>
              </div>

              <div className="mb-0 mr-xl-9">
                <div className="shadow-3 bg-white rounded-4">
                  <form action="/">
                    <div className="subscribe-inline-form-2">
                      <div className="form-group">
                        <label htmlFor="mail">
                          <i className="icon icon-email-84 mb-0" />
                        </label>
                        <input
                          id="mail"
                          type="email"
                          className="form-control"
                          placeholder="Enter your email"
                        />
                      </div>
                      <button className="btn btn-dodger-blue-2 rounded-5">
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
