export const menuItems = [
  {
    name: "",
    label: "Inicio",
  },
  {
    name: "empresas",
    label: "Empresas",
  },
  {
    name: "#precios",
    label: "Precios",
  },
  {
    name: "faq",
    label: "Preguntas Frecuentes",
  },
  {
    name: "acerca",
    label: "Acerca de Nosotros",
  },
  {
    name: "contacto",
    label: "Contacto",
  },
  // {
  //   name: "https://uxtheme.net/product-support/",
  //   label: "Support",
  //   isExternal: true,
  // },
];
